import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { OverlayManagerService } from 'src/app/overlay-manager.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-item-record-dialog',
  templateUrl: './item-record-dialog.component.html',
  styleUrls: ['./item-record-dialog.component.scss']
})
export class ItemRecordDialogComponent implements OnInit {

  ROUTE_TIMELINE = environment.api_location_admin + "/booking/item-timeline/";
  ROUTE_DOWNLOAD_POD = environment.api_location + "/booking/download-pod";
  info;
  timeline;
  showMore = false;

  constructor (private overlayManager: OverlayManagerService, private downloadSvc: FileDownloadService, private http: HttpClient) { }

  ngOnInit(): void {
    const targetURL = this.ROUTE_TIMELINE + this.bookingItemProfile.ID;

    this.http.get(targetURL).subscribe(response => {
      this.info = response;
      this.timeline = this.info.Timeline;
      this.timeline.sort((a, b) => new Date(b.Sortable_DateTime).getTime() - new Date(a.Sortable_DateTime).getTime());
    });
  }

  get bookingItemProfile() {
    return this.overlayManager.bookingItem;
  }

  getPOD(milestoneID) {

    //Given a milestoneID, we can pull out the items dropped off at that milestone
    //The list must be 
    this.http.get(this.ROUTE_DOWNLOAD_POD,{
      params: {
        milestone: milestoneID,
        bookingitemid: this.bookingItemProfile.ID
      }
    }).subscribe(response => {

      const myData = response as any;

      let data:any = this.generateHTMLStructure(myData);
      document.body.appendChild(data);
      html2canvas(data).then((canvas) => {
        let fileWidth = 208;
        let fileHeight = (canvas.height * fileWidth) / canvas.width;
        const FILEURI = canvas.toDataURL('image/png');
        let PDF = new jsPDF('p', 'mm', 'a4');
        let position = 0;
        PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
        const fileName = myData.Milestone.Name+"_"+myData.Milestone.Executed_DateTime+".pdf";
        PDF.save(fileName);
        document.getElementById("PDF-ROOT").remove();
      });

    });

//    this.downloadSvc.downloadPDF(this.ROUTE_POD_STUB + jobID, this.info.Job_Ref_No);
  }

    closePanel() {
    this.overlayManager.closePanel();
  }

  generateHTMLStructure(data) {

    const rootDOM = document.createElement("div");
    rootDOM.id = "PDF-ROOT";
    const wrapperStart = "<div class='p-3'>";
    const header = "<div class='d-flex flex-row'><div class='d-flex flex-fill flex-row'><img src='assets/TIC-Logo-original.png' style='width:200px;'><div class='d-flex flex-column pt-4 pl-3' style='font-size:1.5rem'><h2><strong>TIC TRAFFIC INTERNATIONAL CONSORTIUM(S)</strong></h2><div>70 Alps Ave #04-05 S(498801)</div><div>Tel: (65)68449331</div><div>Fax: (65)6543979</div></div></div><div><div class='d-flex flex-column pt-4 pl-3' style='font-size:1.5rem'><h2><strong>Delivery Order</strong></h2><div>Sent By: </div></div></div>";
    const wrapperEnd = "</div>";

    rootDOM.innerHTML = wrapperStart + header + wrapperEnd;
    
    return rootDOM;
  }  
}