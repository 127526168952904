import { HttpClient, HttpEventType, HttpRequest } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { CartDrawerManagerService } from 'src/app/cart-drawer/cart-drawer-manager.service';
import { OverlayManagerService } from 'src/app/overlay-manager.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { FileDownloadService } from 'src/app/services/file-download.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-tab-customer-booking',
  templateUrl: './tab-customer-booking.component.html',
  styleUrls: ['./tab-customer-booking.component.scss']
})
export class TabCustomerBookingComponent implements OnInit {

  ROUTE_ADMIN_BOOKINGS = environment.api_location + "/bookings/list";
  ROUTE_ADMIN_BOOKING_PROFILE = environment.api_location_admin + "/booking/profile/";
  ROUTE_ADMIN_PROCESS_BOOKING = environment.api_location+"/booking/process-booking";

  ROUTE_ADMIN_CREATE_GRN = environment.api_location_admin+"/booking/create-grn";
  ROUTE_ADMIN_CREATE_GIN = environment.api_location_admin+"/booking/create-gin";

  //ROUTE_ADMIN_LOAD_GRN = environment.api_location_admin+"/booking/load-grn/";
  ROUTE_ADMIN_LOAD_GIN = environment.api_location_admin+"/booking/load-gin/";

  ROUTE_ADMIN_UPDATE_GIN = environment.api_location_admin+"/booking/update-gin/";
  ROUTE_ADMIN_CLOSE_BOOKING = environment.api_location_admin+"/booking/close-booking";

  ROUTE_FILE_UPLOAD = environment.api_location + "/booking/upload-file";
  ROUTE_FILE_DELETE = environment.api_location + "/booking/delete-file";
  ROUTE_FILE_DOWNLOAD = environment.api_location + "/booking/file/";

  bookings;
  warehouses;

  isLoading = true;
  loadingBooking = true;

  selectedBooking;
  loadedBooking;

  deletingBooking = false;

  BOOKING_SUMMARY = 0;
  BOOKING_DETAILS = 1;
  BOOKING_DOCUMENTS = 2;
  BOOKING_ADMIN = 3;
  currentTab = this.BOOKING_DETAILS;

  bookingFilterTerm;
  BOOKING_FILTER_TYPE_ALL = 'ALL';
  BOOKING_FILTER_TYPE_INBOUND = 'GRN';
  BOOKING_FILTER_TYPE_OUTBOUND = 'GIN';
  BOOKING_FILTER_TYPE_TRUCKING = 'TRUCKING';
  BOOKING_FILTER_TYPE_AIR = 'AIR';
  BOOKING_FILTER_TYPE_SEA = 'SEA';

  COMPLETION_FILTER_TYPE_PENDING = 0;
  COMPLETION_FILTER_TYPE_ONGOING = 1;
  COMPLETION_FILTER_TYPE_COMPLETED = 2;

  bookingFilterType = this.BOOKING_FILTER_TYPE_ALL;
  completionFilter = this.BOOKING_FILTER_TYPE_ALL;

  filesToUpload = [];
  fileUploadProcessQueue;
  navigationSubscription;

  GRN_Task;
  GIN_Task;
  Truck_Task;

  constructor (private authSvc: AuthService, private http: HttpClient, private overlayManager: OverlayManagerService, private cartManager: CartDrawerManagerService,
    private staticData: StaticDataService, private fileDownloadService: FileDownloadService, private router: Router) { }

  ngOnInit(): void {

    this.init();
    // Subscribe to router reload
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if(e instanceof NavigationEnd) {
        this.init();
      }
    });
  }

  init() {
    this.getBookings();
    this.http.get(environment.api_location_admin + "/resource/warehouses").subscribe(data => {
      this.warehouses = data;
    });
  }

  isAdmin() {
    return this.authSvc.user() && this.authSvc.user.role == 0;
  }

  openBookingDialog() {
    this.overlayManager.showBookingPanel(0);
  }

  getBookings() {

    let resolver = null;
    const promise = new Promise(resolve => resolver = resolve);

    this.http.get(this.ROUTE_ADMIN_BOOKINGS).subscribe(response => {
      
      this.bookings = response;
      for(const booking of this.bookings) {
        if(booking.Closed_DateTime) {
          booking.status = this.COMPLETION_FILTER_TYPE_COMPLETED;
        } else if(booking.Processed_DateTime) {
          booking.status = this.COMPLETION_FILTER_TYPE_ONGOING;
        } else {
          booking.status = this.COMPLETION_FILTER_TYPE_PENDING;
        }
      }

      if(this.bookings.length > 0) {
        this.selectBooking(this.bookings[0]);
      }

      this.isLoading = false;
      resolver("Bookings Updated");
    });

    return promise;
  }

  selectBooking(booking) {
    this.selectedBooking = booking;
    this.loadingBooking = true;
    this.currentTab = this.BOOKING_DETAILS;
    const targetURL = this.ROUTE_ADMIN_BOOKING_PROFILE + booking.ID;
    const promise = this.http.get(targetURL);
    
    promise.subscribe(response => {
      this.selectedBooking = response;
      (response as any).Documents.forEach(doc => {
        (doc as any).showDeleteFileView = false;
      });
      this.loadedBooking = response;
      this.loadingBooking = false;
    });

    return promise;
  }

  addToCart(item) {
    this.cartManager.addToCart(item, 1);
  }

  isInCart(item) {
    return this.cartManager.inCart(item);
  }

  showItemDetails(item) {
    this.overlayManager.showBookingItemRecord(item);
  }

  selectTab(tab) {
    if(tab != this.currentTab) {
      this.currentTab = tab;
    }

    console.log(this.currentTab);
    if(this.currentTab == this.BOOKING_ADMIN) {
      this.reloadBookingTasks();
    }
  }

  reloadBookingTasks() {
    this.http.get(this.ROUTE_ADMIN_LOAD_GIN+this.selectedBooking.ID).subscribe((response) => {
      this.GIN_Task = response;
    });
  }

  addFile(newFiles: FileList) {

    if(!this.filesToUpload) {
      this.filesToUpload = [];
    }

    //if there's no file, we just take the file list and convert it into an array
    for(let i = 0; i < newFiles.length; i++) {
      const file = newFiles[i];

      let exists = false;

      //Reject if not in pre declared extensions
      if(!this.staticData.isFileTypeAllowed(file)) {
        continue;
      }

      for(let j = 0; j < this.filesToUpload.length; j++) {
        const oldFile = this.filesToUpload[j];

        if((oldFile.name == file.name) && (oldFile.type == file.type) && (oldFile.size == file.size) && (oldFile.lastModified == file.lastModified)) {
          exists = true;
        }
      }

      if(!exists) {
        this.filesToUpload.push(file);
      }
    }
  }

  removeFile(file) {
    this.filesToUpload.splice(this.filesToUpload.indexOf(file), 1);
  }

  getType(fileName) {

    const extension = this.staticData.getExtension(fileName);

    if(extension == "doc" || extension == "docx") {
      return "fas fa-file-word text-primary";
    }

    if(extension == "ppt" || extension == "pptx") {
      return "fas fa-file-powerpoint text-danger";
    }

    if(extension == "xls" || extension == "xlsx") {
      return "fas fa-file-excel text-success";
    }

    if(extension == "pdf") {
      return "fas fa-file-pdf text-danger";
    }

    if(extension == "jpg" || extension == "jpeg" || extension == "png" || extension == "jpe" || extension == "tif" || extension == "tiff" || extension == "gif") {
      return "fas fa-image text-info";
    }

    return "fas fa-file-alt";
  }

  getSize(bytes) {

    if(bytes === 0) return '0 Bytes';
    const k = 1024;
    const decimals = 2;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  uploadFiles() {

    if(this.filesToUpload && this.filesToUpload.length > 0) {
      this.fileUploadProcessQueue = [];

      for(const selectedFile of this.filesToUpload) {
        const fileProcessItem = {
          file: selectedFile,
          uploadComplete: false,
          error: false,
          progress: 0
        };

        this.fileUploadProcessQueue.push(fileProcessItem);
      }
      const fileUploadBehaviorSubjects = [];
      for(const fileQueueItem of this.fileUploadProcessQueue) {
        const uploadForm = new FormData();
        uploadForm.append("Booking_ID", this.selectedBooking.ID);
        uploadForm.append("Uploaded_File", fileQueueItem.file);

        const request = new HttpRequest('POST', this.ROUTE_FILE_UPLOAD, uploadForm, {
          reportProgress: true,
          responseType: 'json'
        });

        const behaviorSubject = this.http.request(request);

        behaviorSubject.subscribe(
          response => {
            let data = response as any;

            fileQueueItem.uploadComplete = true;

            if(data.type === HttpEventType.Response) {
              fileQueueItem.uploadComplete = true;
              this.selectBooking(this.selectedBooking);
              this.currentTab = this.BOOKING_DETAILS;
              this.filesToUpload = [];
            }

            if(data.type === HttpEventType.UploadProgress) {
              fileQueueItem.progress = Math.round(100 * data.loaded / data.total);
            }
          },
          error => {
            fileQueueItem.uploadComplete = false;
            fileQueueItem.error = true;
          }
        );

        fileUploadBehaviorSubjects.push(behaviorSubject);
      }
    }
  }

  downloadFile(file) {
    const url = this.ROUTE_FILE_DOWNLOAD + file.ID;
    this.fileDownloadService.downloadFile(url, file.Original_File_Name);
  }

  openDeleteFile(doc) {
    doc.showDeleteFileView = true;
  }

  undoDelete(doc) {
    doc.showDeleteFileView = false;
  }

  deleteFile(file) {
    const payload = { File_ID: file.ID };
    this.http.post(this.ROUTE_FILE_DELETE, payload).subscribe(() => {
      this.selectBooking(this.selectedBooking);
      this.currentTab = this.BOOKING_DOCUMENTS;
    });
  }

  updateBookingFilterType() {
    this.getBookings().then(response => {

      let filteredList = this.bookings;

      if(this.bookingFilterType == this.BOOKING_FILTER_TYPE_ALL) {
        //Ignore
      } else {
        filteredList = filteredList.filter(booking => {
          return booking.Booking_Type == this.bookingFilterType;
        });
      }

      if(this.completionFilter == this.BOOKING_FILTER_TYPE_ALL) {
        //Ignore
      } else {
        filteredList = filteredList.filter(booking => {
          return booking.status == this.completionFilter;
        });
      }

      this.bookings = filteredList;
    });
  }
  
  isProcessed() {
    return this.selectedBooking.Processed_DateTime !== null;
  }

  isClosed() {
    return this.selectedBooking.Closed_DateTime !== null;
  }

  processBooking() {

    const payload = {
      Booking_ID:null
    };
    payload.Booking_ID = this.selectedBooking.ID

    this.http.post(this.ROUTE_ADMIN_PROCESS_BOOKING, payload).subscribe((response) => {
      this.selectBooking(this.selectedBooking).subscribe((response2)=> {

        let promise;

        switch(this.selectedBooking.Booking_Type) {
          case this.BOOKING_FILTER_TYPE_INBOUND: 
            promise = this.http.post(this.ROUTE_ADMIN_CREATE_GRN, payload);
            break;
          case this.BOOKING_FILTER_TYPE_OUTBOUND:
            promise = this.http.post(this.ROUTE_ADMIN_CREATE_GIN, payload);
            break;
        }
        
        promise.subscribe((response) => {
          this.selectTab(this.BOOKING_ADMIN);
        });
      })
    });

  }

  updateGIN(task) { 
    this.http.post(this.ROUTE_ADMIN_UPDATE_GIN, task).subscribe(() => {
      this.reloadBookingTasks();
    });
  }

  closeBooking() {
    const payload = {
      Booking_ID:null
    };

    payload.Booking_ID = this.selectedBooking.ID
    this.http.post(this.ROUTE_ADMIN_CLOSE_BOOKING, payload).subscribe((response) => {
      this.reloadBookingTasks();
    });
  }

  deleteBookingStep1() {
    this.deletingBooking = true;
  }

  cancelDelete() {
    this.deletingBooking = false;
  }

  deleteBookingStep2() {
    if(!this.deletingBooking) {
      return;
    }
  }
}
