import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  loadingComplete = false;
  private _countries;
  private _ports;
  private _mime;

  constructor (private http: HttpClient) { }

  init() {
    this.http.get("./assets/countries_full.json").subscribe(data => {
      this._countries = data;
      this.loadingComplete = true;
    });

    this.http.get("./assets/mime_types.json").subscribe(data => {
      this._mime = data;
    });

    this.http.get("./assets/ports_full.json").subscribe(data => {
      this._ports = data;
    });
  }

  get countries() {

    if(!this._countries) {
      return [];
    }

    return this._countries;
  }

  isFileTypeAllowed(file) {

    if(!file && !file.name) {
      return false;
    }

    const fileExt = this.getExtension(file.name);

    //have to check if both extension and type is legit at least
    if(!this._mime[fileExt]) {
      return false;
    }

    if(this._mime[fileExt] != file.type) {
      return false;
    }

    return true;
  }

  getExtension(fname) {
    let ext = fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);

    if(ext && ext.length > 0) {
      ext = ext.toLowerCase();
    }

    return ext;
  }

  getMime(ext) {
    return this._mime[ext];
  }

  get Ports() {
    return this._ports;
  }

  getPorts(countryCode = null, type = 0) {

    if(!countryCode) {
      return null;
    }

    for(const countryData of this.Ports) {

      if(countryData.country_code == countryCode) {
        if(type == 0) {
          return countryData.airports;
        } else {
          return countryData.ports;
        }
      }
    }

    return null;
  }

  getPortName(code, country, type = 0) {

    if(!country) {
      return;
    }

    if(!code) {
      return;
    }

    const ports = this.getPorts(country, type);

    if(type == 0) {
      for(const port of ports) {
        if(port.iata == code) {
          return port.name;
        }
      }
    } else {
      for(const port of ports) {
        if(port.code == code) {
          return port.name;
        }
      }
    }

    return;
  }
}
