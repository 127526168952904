import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { CartDrawerComponent } from './cart-drawer/cart-drawer.component';

import { WorkspaceComponent } from './workspace/workspace.component';
import { TabCustomerBookingComponent } from './tabcontent/tab-customer-booking/tab-customer-booking.component';
import { ItemRecordDialogComponent } from './tabcontent/tab-customer-booking/item-record-dialog/item-record-dialog.component';
import { MobileWorkspaceComponent } from './mobile-workspace/mobile-workspace.component';

import { AuthInterceptorService } from './auth/auth-interceptor.service';

import { AdminBookingFilterPipe } from './services/admin-booking-filter.pipe';
import { DragDropDirective } from './directives/drag-drop.directive';
import { BookingServiceDetailsComponent } from './tabcontent/tab-customer-booking/booking-service-details/booking-service-details.component';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('./tabcontent/tab-customer-dashboard/tab-customer-dashboard.module').then(m=>m.TabCustomerDashboardModule)
  },
  { path: 'database',
    loadChildren: () => import('./tabcontent/tab-customer-database/tab-customer-database.module').then(m=>m.TabCustomerDatabaseModule)
  },
  { path: 'bookings', component: TabCustomerBookingComponent },
  {
    path: 'new-booking',
    loadChildren: () => import('./tabcontent/new-customer-booking/new-customer-booking.module').then(m=>m.NewCustomerBookingModule)
  },
  { path: 'products',
    loadChildren: () => import('./tabcontent/tab-customer-products/tab-customer-products.module').then(m=>m.TabCustomerProductsModule)
  },
  {
    path: '**', //If path doesn't match anything reroute to /authentication/signin
    redirectTo: 'bookings',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    ToolbarComponent,
    CartDrawerComponent,
    WorkspaceComponent,
    MobileWorkspaceComponent,
    TabCustomerBookingComponent,
    DragDropDirective,
    ItemRecordDialogComponent,
    AdminBookingFilterPipe,
    BookingServiceDetailsComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgSelectModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
