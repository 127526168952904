import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Sanitizer } from '@angular/core';
import { StaticDataService } from './static-data.service';

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {
  constructor (private http: HttpClient, private staticDataService: StaticDataService) { }


  downloadFile(url, filename) {
    let headers = new HttpHeaders({});
    this.http.get(url, { headers: headers, responseType: 'text' }).subscribe(response => {

      const downloadedBlob = atob(response);

      const byteNumbers = new Array(downloadedBlob.length);
      for(let i = 0; i < downloadedBlob.length; i++) {
        byteNumbers[i] = downloadedBlob.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      let mime = this.resolveMime(this.staticDataService.getExtension(filename));
      const blob = new Blob([byteArray], { type: mime });

      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.download = filename;
      downloadLink.click();
    });
  }

  downloadPDF(url, filename) {

    let headers = new HttpHeaders({});
    headers = headers.set('Accept', 'application/pdf');

    this.http.get(url, { headers: headers, responseType: 'blob' }).subscribe(response => {

      const downloadedBlob = response;
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(downloadedBlob);
      downloadLink.download = filename + ".pdf";
      downloadLink.click();
    });
  }

  resolveMime(ext) {
    // resolve ext to mime type lel
    return this.staticDataService.getMime(ext);
  }
}

