import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {

  ROUTE_ALLOWED_FUNCTIONS = environment.api_location + "/allowed-functions";
  sidebarItems = [
    {
      name: "New Booking",
      icon: "fa-plus",
      link: "/new-booking",
      allowed: true
    },
    {
      name: "Bookings",
      icon: "fa-book",
      link: "/bookings",
      allowed: false
    },
    {
      name: "Products",
      icon: "fa-boxes",
      link: "/products",
      allowed: false
    },
    {
      name: "Dashboard",
      icon: "fa-chart-bar",
      link: "/dashboard",
      allowed: true
    },
    {
      name: "Data",
      icon: "fa-database",
      link: "/database",
      allowed: true
    }
  ];

  constructor (private http: HttpClient) { }

  // For now just check warehouse allowed or not. Future can add more restraints here.
  checkAllowedFunctions() {
    this.http.get(this.ROUTE_ALLOWED_FUNCTIONS).subscribe(response => {
      let allowedFunctions = response as any;
      this.sidebarItems.find(sidebarItem => sidebarItem.name == 'Bookings').allowed = allowedFunctions.Bookings;
      this.sidebarItems.find(sidebarItem => sidebarItem.name == 'Products').allowed = allowedFunctions.Products;
    });
  }
}