<div class="overlay-background" (click)="closePanel()">
    &nbsp;
</div>
<div class="dialog-container">
    <div class="card">
        <div class="card-body pl-0 pr-0">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col">
                        <h4>{{bookingItemProfile.Product_Name}}</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <table class="table table-bordered">
                            <tr>
                                <td>
                                    <label class="control-label">
                                        Package Type
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Package_Type}}
                                    </div>
                                </td>
                                <td colspan="2">
                                    <label class="control-label">
                                        Item Description
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Description}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="control-label">
                                        SKU
                                    </label>
                                    <div>
                                        {{bookingItemProfile.SKU}}
                                    </div>
                                </td>
                                <td>
                                    <label class="control-label">
                                        Part No.
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Part_No}}
                                    </div>
                                </td>
                                <td>
                                    <label class="control-label">
                                        Serial No.
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Serial_No}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label class="control-label">
                                        Total Weight (KG)
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Weight}}
                                    </div>
                                </td>
                                <td>
                                    <label class="control-label">
                                        No. of {{bookingItemProfile.Package_Type}}
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Quantity}}
                                    </div>
                                </td>
                                <td>
                                    <label class="control-label">
                                        Qty/{{bookingItemProfile.Package_Type}}
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Denomination}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <label class="control-label">
                                        Dimensions (L x W x H cm)
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Length}} x {{bookingItemProfile.Width}} x
                                        {{bookingItemProfile.Height}}
                                    </div>
                                </td>
                                <td>
                                    <label class="control-label">
                                        Value
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Dollar_Value?bookingItemProfile.Dollar_Value:"-"}}
                                        {{bookingItemProfile.Currency?bookingItemProfile.Currency:""}}
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="3">
                                    <label class="control-label">
                                        Notes
                                    </label>
                                    <div>
                                        {{bookingItemProfile.Note?bookingItemProfile.Note:"-"}}
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="col-6 timeline-scrollable">
                        <div *ngIf="!timeline">
                            <div class="progress">
                                <div class="indeterminate"></div>
                            </div>
                        </div>
                        <div class="overflow-hidden" *ngIf="timeline">
                            <ng-container
                                *ngFor="let timelineEvent of timeline; let first = first; let last = last; let i = index">
                                <div class="d-flex flex-row">
                                    <ng-container *ngIf="i < 5 && !showMore">
                                        <div class="d-flex flex-column">
                                            <div class="timeline-circle" *ngIf="!first">
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Created_Event'">
                                                    <i class="fas fa-book-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Processed_Event'">
                                                    <i class="fas fa-thumbs-up"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Picking_Up_Event' || timelineEvent.Event_Type == 'Truck_Dropping_Off_Event' || timelineEvent.Event_Type == 'Item_Scheduled_Event'">
                                                    <i class="fas fa-truck"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Picked_Up_Event'"><i
                                                        class="fas fa-warehouse"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Dropped_Off_Event' || timelineEvent.Event_Type == 'Booking_Closed_Event'">
                                                    <i class="fas fa-check"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Air_Event'">
                                                    <i class="fas fa-plane-departure"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Sea_Event'">
                                                    <i class="fas fa-ship"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Inbound_Event'">
                                                    <i class="fas fa-box-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Outbound_Event'">
                                                    <i class="fas fa-truck-loading"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Report_Event' || timelineEvent.Event_Type == 'Error_Event'">
                                                    <i class="fas fa-exclamation"></i>
                                                </div>
                                            </div>
                                            <div class="timeline-circle" *ngIf="first"
                                                [ngClass]="{'bg-secondary': timelineEvent['Event_Type']=='Truck_Picked_Up_Event',
                                                            'bg-primary': timelineEvent['Event_Type']=='Booking_Created_Event' || timelineEvent['Event_Type'] == 'Booking_Processed_Event' || timelineEvent['Event_Type']=='Sea_Event', 
                                                            'bg-warning': timelineEvent['Event_Type']=='Item_Scheduled_Event' || timelineEvent['Event_Type']=='Truck_Picking_Up_Event' || timelineEvent['Event_Type']=='Truck_Dropping_Off_Event',
                                                            'bg-success': timelineEvent['Event_Type']=='Truck_Dropped_Off_Event' || timelineEvent['Event_Type']=='Booking_Closed_Event',
                                                            'bg-info': timelineEvent['Event_Type']=='Air_Event' || timelineEvent['Event_Type'] == 'Inbound_Event' || timelineEvent['Event_Type'] == 'Outbound_Event',
                                                            'bg-danger': timelineEvent['Event_Type']=='Report_Event' || timelineEvent['Event_Type'] == 'Error_Event'}">
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Created_Event'">
                                                    <i class="fas fa-book-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Processed_Event'">
                                                    <i class="fas fa-thumbs-up"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Picking_Up_Event' || timelineEvent.Event_Type == 'Truck_Dropping_Off_Event' || timelineEvent.Event_Type == 'Item_Scheduled_Event'">
                                                    <i class="fas fa-truck"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Picked_Up_Event'"><i
                                                        class="fas fa-warehouse"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Dropped_Off_Event' || timelineEvent.Event_Type == 'Booking_Closed_Event'">
                                                    <i class="fas fa-check"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Air_Event'">
                                                    <i class="fas fa-plane-departure"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Sea_Event'">
                                                    <i class="fas fa-ship"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Inbound_Event'">
                                                    <i class="fas fa-box-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Outbound_Event'">
                                                    <i class="fas fa-truck-loading"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Report_Event' || timelineEvent.Event_Type == 'Error_Event'">
                                                    <i class="fas fa-exclamation"></i>
                                                </div>
                                            </div>
                                            <div div class="d-flex flex-fill timeline-line" *ngIf="!last">
                                                <div class="d-flex flex-fill border-left"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column pl-3">
                                            <div class="mb-1">
                                                <strong>{{timelineEvent.Event_Description}}</strong>
                                                <div (click)="getPOD(timelineEvent.Milestone_ID)" class="hyperlink mb-2"
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Dropped_Off_Event'">
                                                    <a><i class="fas fa-download pr-1"></i> Download POD</a>
                                                </div>
                                            </div>
                                            <div class="pb-3">
                                                {{timelineEvent.Event_DateTime | date: "yyyy-MM-dd h:mm a z"}}
                                            </div>
                                        </div>
                                    </ng-container>
                                    <div *ngIf="i == 5 && !showMore"
                                        class="btn btn-secondary d-flex flex-row flex-fill justify-content-center"
                                        (click)="showMore = !showMore">
                                        <span *ngIf="!showMore">Click to show more</span>
                                    </div>
                                    <ng-container *ngIf="showMore">
                                        <div class="d-flex flex-column">
                                            <div class="timeline-circle" *ngIf="!first">
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Created_Event'">
                                                    <i class="fas fa-book-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Processed_Event'">
                                                    <i class="fas fa-thumbs-up"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Picking_Up_Event' || timelineEvent.Event_Type == 'Truck_Dropping_Off_Event' || timelineEvent.Event_Type == 'Item_Scheduled_Event'">
                                                    <i class="fas fa-truck"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Picked_Up_Event'"><i
                                                        class="fas fa-warehouse"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Dropped_Off_Event'">
                                                    <i class="fas fa-check"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Air_Event'">
                                                    <i class="fas fa-plane-departure"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Sea_Event'">
                                                    <i class="fas fa-ship"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Inbound_Event'">
                                                    <i class="fas fa-box-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Outbound_Event'">
                                                    <i class="fas fa-truck-loading"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Report_Event' || timelineEvent.Event_Type == 'Error_Event'">
                                                    <i class="fas fa-exclamation"></i>
                                                </div>
                                            </div>
                                            <div class="timeline-circle" *ngIf="first"
                                                [ngClass]="{'bg-secondary': timelineEvent['Event_Type']=='Truck_Picked_Up_Event',
                                                            'bg-primary': timelineEvent['Event_Type']=='Booking_Created_Event' || timelineEvent['Event_Type'] == 'Booking_Processed_Event' || timelineEvent['Event_Type']=='Sea_Event', 
                                                            'bg-warning': timelineEvent['Event_Type']=='Item_Scheduled_Event' || timelineEvent['Event_Type']=='Truck_Picking_Up_Event' || timelineEvent['Event_Type']=='Truck_Dropping_Off_Event',
                                                            'bg-success': timelineEvent['Event_Type']=='Truck_Dropped_Off_Event',
                                                            'bg-info': timelineEvent['Event_Type']=='Air_Event' || timelineEvent['Event_Type'] == 'Inbound_Event' || timelineEvent['Event_Type'] == 'Outbound_Event',
                                                            'bg-danger': timelineEvent['Event_Type']=='Report_Event' || timelineEvent['Event_Type'] == 'Error_Event'}">
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Created_Event'">
                                                    <i class="fas fa-book-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Booking_Processed_Event'">
                                                    <i class="fas fa-thumbs-up"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Truck_Picking_Up_Event' || timelineEvent.Event_Type == 'Truck_Dropping_Off_Event' || timelineEvent.Event_Type == 'Item_Scheduled_Event'">
                                                    <i class="fas fa-truck"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Picked_Up_Event'"><i
                                                        class="fas fa-warehouse"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Truck_Dropped_Off_Event'">
                                                    <i class="fas fa-check"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Air_Event'">
                                                    <i class="fas fa-plane-departure"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Sea_Event'">
                                                    <i class="fas fa-ship"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Inbound_Event'">
                                                    <i class="fas fa-box-open"></i>
                                                </div>
                                                <div *ngIf="timelineEvent.Event_Type == 'Outbound_Event'">
                                                    <i class="fas fa-truck-loading"></i>
                                                </div>
                                                <div
                                                    *ngIf="timelineEvent.Event_Type == 'Report_Event' || timelineEvent.Event_Type == 'Error_Event'">
                                                    <i class="fas fa-exclamation"></i>
                                                </div>
                                            </div>
                                            <div div class="d-flex flex-fill timeline-line" *ngIf="!last">
                                                <div class="d-flex flex-fill border-left"></div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column pl-3">
                                            <div class="mb-1">
                                                <strong>{{timelineEvent.Event_Description}}</strong>
                                                <div (click)="getPOD(timelineEvent.Milestone_ID)" class="hyperlink mb-2"
                                                    *ngIf="info.Task_ID && timelineEvent.Event_Type == 'Truck_Dropped_Off_Event'">
                                                    <a><i class="fas fa-download pr-1"></i> Download POD</a>
                                                </div>
                                            </div>
                                            <div class="pb-3">
                                                {{timelineEvent.Event_DateTime | date: "yyyy-MM-dd h:mm a z"}}
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                                <div *ngIf="showMore && i == timeline.length-1"
                                    class="btn btn-secondary d-flex flex-row flex-fill justify-content-center"
                                    (click)="showMore = !showMore">
                                    <span *ngIf="showMore">Click to hide</span>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>