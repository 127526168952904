import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor (private authSvc: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let finalReq;
    let cookie = this.authSvc.getCookie('CLARITY-AUTH-TOKEN');
    //tic test customer account
    //let cookie = "eyJ1c2VyTmFtZSI6Imppbmd0aW5nQG9kZGl0eS5zZyIsImV4cGlyZXNJbiI6MzYwMDAwMDAwLCJhcHBsaWNhdGlvbktleSI6IklZVmEwNlREc3JUdlJXWmF6M2luZFdvSmRKZ3Rsb2tnIiwiY3JlYXRlZCI6IjIwMjItMDMtMjhUMDA6MTQ6NTkrMDg6MDAifQ==:kdG9nckGu6j3+ZS41zkYtcssJKLsoTkYjVz2OmiOytc=";
    if(!cookie) {// if cookie does not exist, we reject everything except the call to authenticate
      if(req.url.includes(environment.authentication_server + "/authenticate/") || req.url.includes("./assets")) {
        finalReq = req.clone();
      } else {
        //reject 
       window.location.href = "https://login.ticsin.com";
        return; //nah we won't reach this place at all because the browser will be told to go home.
      }
    } else { // Cookie exists, we are going to 
      finalReq = req.clone({
        headers: req.headers.set("CLARITY-AUTH-TOKEN", cookie)
      });
    }
    return next.handle(finalReq).pipe(
      tap((httpEvent: any) => {
        // Skip httpRequest
        if(httpEvent.type === 0) {
          return;
        }

        if(httpEvent instanceof HttpResponse) {
          if(httpEvent.headers.has('CLARITY-AUTH-TOKEN')) {
            this.authSvc.user = httpEvent.headers.get('CLARITY-AUTH-TOKEN');
            this.authSvc.setCookie('CLARITY-AUTH-TOKEN', httpEvent.headers.get('CLARITY-AUTH-TOKEN'));
          }
        }
      })
    );
  }
}
