import {Injectable} from '@angular/core';
import {OverlayManagerService} from '../overlay-manager.service';
import {BookingCartItem} from './booking-cart-item';

@Injectable({
  providedIn: 'root'
})
export class CartDrawerManagerService {
  readonly DISABLED = 0;
  readonly SCHEDULE_JOB = 1;
  readonly ADD_INBOUND = 2;
  readonly ADD_OUTBOUND = 3;

  readonly CART_TYPE_PRODUCT = 0;
  readonly CART_TYPE_ITEM_RECORD = 1;
  readonly CART_TYPE_GRN_SPECIFIC = 2;

  cartVisible = false;
  cartItems: BookingCartItem[] = [];
  currentCartAction = this.DISABLED;

  constructor (private overlayManager: OverlayManagerService) { 
      // const item1:BookingCartItem = { "quantity": 5, "denominator": 1, "name": "SP330 Ethernet Adapter, 10Gb Optical Interface(Intel X710), 2-P ort, SFP + (with 2x Multi - mode Optical Transceiver), Pcle 3.0 ", "description": "SP330 Ethernet Adapter, 10Gb Optical Interface(Intel X710), 2-P ort, SFP + (with 2x Multi - mode Optical Transceiver), Pcle 3.0 ", "SKU": "02311WTT", "partNo": "", "identifier": "02311WTT", "productID": "b843dd76-640c-4057-94b3-bd67257977eb", "serialised": false, "cartType": 0, "selected": true } as BookingCartItem;
      // const item2:BookingCartItem = { "quantity": 3, "denominator": 1, "name": "Hard Disk,8000GB,SATA 6Gb/s,7200rpm,3.5inch,128MB cache or above,Hot plug,Built In,No Cable,For Server Only", "description": "Hard Disk,8000GB,SATA 6Gb/s,7200rpm,3.5inch,128MB cache or above,Hot plug,Built In,No Cable,For Server Only", "SKU": "06210496", "partNo": "N8TST7W3512E", "identifier": "06210496", "productID": "1bbe8413-6992-4993-831e-1a996d093f0e", "serialised": false, "cartType": 0, "selected": true } as BookingCartItem;
      // const item3:BookingCartItem = { "quantity": 1, "denominator": 1, "name": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "description": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "SKU": "04052666", "partNo": "1904052666", "Serial_No": "1904052666/0217482144S002662", "identifier": "04052666", "Item_Record_ID": "02fce2f2-e352-4fdf-b3bf-719e3f367160", "serialised": true, "cartType": 1, "selected": true } as BookingCartItem;
      // const item4:BookingCartItem = { "quantity": 1, "denominator": 1, "name": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "description": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "SKU": "04052666", "partNo": "1904052666", "Serial_No": "1904052666/0217482144S002649", "identifier": "04052666", "Item_Record_ID": "306bf99f-c4af-4eb7-a5a3-ab6592562983", "serialised": true, "cartType": 1, "selected": true } as BookingCartItem;
      // const item5:BookingCartItem =       { "quantity": 1, "denominator": 1, "name": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "description": "IT Equipment Cable,Signal Cable,0.65m,(H2X25(1.0)),(4*(1P*30AWG+1*Drain)+3* 30AWG Shield+4*(1P*30AWG)+22*UL1571 28AWG),(H2X25(1.", "SKU": "04052666", "partNo": "1904052666", "Serial_No": "1904052666/0217482144S002105", "identifier": "04052666", "Item_Record_ID": "31dc866c-58bd-4753-a252-9fba993728b5", "serialised": true, "cartType": 1, "selected": true } as BookingCartItem;

      // this.cartItems.push(item1);
      // this.cartItems.push(item2);
      // this.cartItems.push(item3);
      // this.cartItems.push(item4);
      // this.cartItems.push(item5);

  }

  toggleCart() {
    this.cartVisible = !this.cartVisible;
  }

  openCart() {
    this.cartVisible = true;
  }

  addToCart(targetItem, cartType) {

    //We need some important details to match properly 
    //GRN_ID
    //SKU
    //Item_Record_ID

    //step 1, we check if we need to add a new booking cart item
    //Add cartType to item before calling inCart....
    const item = new BookingCartItem();

    item.name = targetItem.Product_Name;
    item.description = targetItem.Product_Description;


    item.SKU = targetItem.SKU;
    item.partNo = targetItem.Part_No;
    item.Serial_No = targetItem.Serial_No;
    
    item.identifier = targetItem.SKU;

    item.productID = targetItem.ID;
    item.Item_Record_ID = targetItem.Item_Record_ID;
    item.quantity = 1;

    if(item.Serial_No) {
      item.serialised = true;
    } else {
      item.serialised = false;
    }

    item.cartType = cartType;
    item.selected = true;

    //check if item exists in cart
    //Check if serial no AND sku exists in carted item
    let exists = false;

    if(item.serialised) {
      for(let cartedItem of this.cartItems) {
        if(cartedItem.Serial_No == item.Serial_No && cartedItem.SKU == item.SKU) {
          exists = true;
        }
      }
    } else {
      for(let cartedItem of this.cartItems) {
        if(cartedItem.SKU == item.SKU) {
          exists = true;
          cartedItem.quantity += 1;
        }
      }
    }

    if(!exists) {
      this.cartItems.push(item);      
    }

    //make the cart visible
    if(!this.cartVisible) {
      this.cartVisible = true;
    }
  }

  removeFromCart(targetItem) {
    const index = this.cartItems.indexOf(targetItem);
    if(index > -1) {
      this.cartItems.splice(index, 1);
    }
  }

  inCart(item) {
    //PLEASE NOTE THAT inCart = true does not mean immediate rejecction
    //We are just trying to find out if the item is inside the cart or not

    //there are two levels of carting
    //product level vs itemised level
    //we need to decide where this is coming from first
    //If this comes from the product catalog screen
    switch(item.cartType) {
      //if it is carting on the product level, the user likely does not want to assign stuffs themselves
      //the system should pick out the items during carting resolution
      //We just check if this is the same productID and call it a day
      case this.CART_TYPE_PRODUCT:
        for(const cartItem of this.cartItems) {
          if(item.ID == cartItem.productID) {
            return true;
          }
        }
        break;

      //GRN specific carting should provide the GRN/Item record pair ID
      //We just match this. This should usually be very specific to an item in a bin, within a warehouse
      case this.CART_TYPE_GRN_SPECIFIC:
        for(const cartItem of this.cartItems) {
          if(item.ID == cartItem.GRN_ID) {
            return true;
          }
        }
        break;

      //Sometimes we have a item that is adhoc or is not in our current warehosue
      //Since is item is in the system and TIC is required to track its movements
      case this.CART_TYPE_ITEM_RECORD:
        for(const cartItem of this.cartItems) {
          if(item.Item_Record_ID == cartItem.Item_Record_ID) {
            return true;
          }
        }
        break;
    }

    //After checking the item records/product level details and the GRN details
    //We can safely determine that the item is not in the cart, otherwise it would be true
    return false;
  }

  inCartItem(item) {

    // Return the cartItem
    switch(item.cartType) {
      case this.CART_TYPE_PRODUCT:
        for(const cartItem of this.cartItems) {
          if(item.ID == cartItem.productID) {
            return cartItem;
          }
        }
        break;

      case this.CART_TYPE_GRN_SPECIFIC:
        for(const cartItem of this.cartItems) {
          if(item.ID == cartItem.GRN_ID) {
            return cartItem;
          }
        }
        break;

      case this.CART_TYPE_ITEM_RECORD:
        for(const cartItem of this.cartItems) {
          if(item.Item_Record_ID == cartItem.Item_Record_ID) {
            return cartItem;
          }
        }
        break;
    }
    return false;
  }

  retrieveItemInfo() {
    // Retrieve info on with given item record
  }
}
