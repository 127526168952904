export const environment = {
  production: false,
  authentication_server: "https://tic-authen.ticsin.com",
  api_location: "https://move-api.ticsin.com/mycargo",
  api_location_admin: "https://move-api.ticsin.com/mycargo-admin",
  api_gcp: "AIzaSyBzfbJVa7lv4iz1NOqEd9PONkAlsYpwIPk",
};

// export const environment = {
//   production: false,
//   authentication_server: "https://tic-authen.ticsin.com",
//   api_location: "http://localhost/mycargo",
//   api_location_admin: "http://localhost/mycargo-admin",
//   api_gcp: "AIzaSyBzfbJVa7lv4iz1NOqEd9PONkAlsYpwIPk",
// };