import { Component, OnInit } from '@angular/core';
import { OverlayManagerService } from '../overlay-manager.service';
import { CartDrawerManagerService } from './cart-drawer-manager.service';

@Component({
  selector: 'app-cart-drawer',
  templateUrl: './cart-drawer.component.html',
  styleUrls: ['./cart-drawer.component.scss']
})
export class CartDrawerComponent implements OnInit {

  readonly DISABLED = 0;
  readonly ACTION_BOOKING = 1;
  readonly ACTION_ORDER = 2;

  selectAll = true;

  selectedAction = 0;

  constructor(private CartDrawerManager: CartDrawerManagerService, private overlayManager: OverlayManagerService) { }

  ngOnInit(): void {
  }

  toggleSelectAll() {
    this.selectAll = !this.selectAll;

    for(const cartItem of this.CartDrawerManager.cartItems) {
      cartItem.selected = this.selectAll;
    }
  };

  get overflowing() {
    return (window.innerHeight - (this.cartItems.length * 105) - 100) < 0
  }

  get cartItems() {
    return this.CartDrawerManager.cartItems;
  }
  
  launchBookingDialog() {
    //this.overlayManager.showBookingPanel(this.cartItems); 
    this.CartDrawerManager.toggleCart();
  }

  removeItem(item) {
    this.CartDrawerManager.removeFromCart(item);
  }
}
