import { Component, Input, OnInit } from '@angular/core';
import { StaticDataService } from 'src/app/services/static-data.service';

@Component({
  selector: 'app-booking-service-details',
  templateUrl: './booking-service-details.component.html',
  styleUrls: ['./booking-service-details.component.scss']
})
export class BookingServiceDetailsComponent implements OnInit {

  @Input() loadedBooking;
  loadedConfigs;


  constructor (private staticData: StaticDataService) { }

  ngOnInit(): void {
    this.loadConfigs();
  }

  loadConfigs() {
    this.loadedConfigs = JSON.parse(this.loadedBooking.Configurations);
  }

  filterCommodity(commodities) {
    // commodities is an object { }
    if(!commodities || commodities === undefined || commodities == null) return [];
    let filtered = [];
    Object.keys(commodities).forEach(key => {
      let value = commodities[key];
      if(value == true) {
        filtered.push(key);
      }
    });
    return filtered;
  }

  getCountryName(countryCode) {

    for(const country of this.staticData.countries) {
      if(country.code2 == countryCode) {
        return country.name;
      }
    }

    return;
  }

  getPortName(code, country, type = 0) {

    if(!country) {
      return;
    }

    if(!code) {
      return;
    }

    const ports = this.getPorts(country, type);

    if(type == 0) {
      for(const port of ports) {
        if(port.iata == code) {
          return port.name;
        }
      }
    } else {
      for(const port of ports) {
        if(port.code == code) {
          return port.name;
        }
      }
    }

    return;
  }

  getPorts(countryCode = null, type = 0) {

    if(!countryCode) {
      return null;
    }

    for(const countryData of this.staticData.Ports) {

      if(countryData.country_code == countryCode) {
        if(type == 0) {
          return countryData.airports;
        } else {
          return countryData.ports;
        }
      }
    }

    return null;
  }

}
