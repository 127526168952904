import { Component, OnInit } from '@angular/core';
import { CartDrawerManagerService } from '../cart-drawer/cart-drawer-manager.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  constructor(private cartManager: CartDrawerManagerService) { }

  ngOnInit(): void {
  }

  get cartCount() {
    return this.cartManager.cartItems.length;
  }

  toggleCart() {
    this.cartManager.toggleCart();
  }
}
