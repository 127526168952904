<div *ngIf="cartItems.length > 0">
  <div class="d-flex flex-fill flex-row pt-2 pb-2">
    <div class="d-flex flex-column justify-content-center p-2">
      <input type="checkbox" [checked]="true" [(ngModel)]="selectAll" (change)="toggleSelectAll()">
    </div>
    <div class="d-flex flex-column flex-fill justify-content-center pl-1">
      <strong>Select All</strong>
    </div>
  </div>
</div>
<div class="d-flex flex-column overflow-hidden" [ngClass]="{'flex-fill': overflowing}" *ngIf="cartItems.length > 0">
  <div class="d-flex flex-column scroll">
    <div class="d-flex flex-row overflow-hidden border-bottom pt-2 pb-2" *ngFor="let cartItem of cartItems">
      <div class="d-flex flex-column p-2">
        <input type="checkbox" [(ngModel)]="cartItem.selected">
      </div>
      <div class="d-flex flex-column flex-fill pl-1">
        <div class="cart-item-text"><strong>{{cartItem.SKU}}</strong></div>
        <div class="cart-item-text" *ngIf="cartItem.Serial_No">{{cartItem.Serial_No}}</div>
        <div class="cart-item-text">Quantity: {{cartItem.quantity}}</div>
      </div>
      <div class="d-flex flex-column p-2">
        <div (click)="removeItem(cartItem)"><i class="fas fa-trash-alt"></i></div>
      </div>
    </div>
  </div>
</div>
<div class="pt-2 pl-1 pr-3" *ngIf="cartItems.length > 0">
  <div class="btn-block btn-group m-2">
    <button class="btn btn-primary" [disabled]="cartItems.length == 0" [routerLink]="['/new-booking']" (click)="launchBookingDialog()">
      <div>Make Booking</div>
    </button>
  </div>
</div>
<div class="mt-3 p-2 mb-2" *ngIf="!cartItems || cartItems.length == 0">
  <div class="text-center mb-2">
    No items have been selected
  </div>
  <div class="text-center text-warning">
    <i class="fas fa-sad-tear fa-2x"></i>
  </div>
</div>