import { FormControl } from "@angular/forms";

export class BookingCartItem {

    //compulsory class members
    productID: string;
    serialised: boolean;
    quantity: number;
    denominator: number;
    identifier: string;
    cartType: number;
    Dollar_Value: number;
    Currency: string;
    Status: string;
    package_type: string;

    //Based on the productID, i should be able to get the following at least
    name: string;
    description: string;
    SKU: string;
    partNo: string;

    //If the user carts from the product profile, we will have item specific information
    Item_Record_ID: string;
    Serial_No: string;
    GRN_ID: string;

    selected: boolean;

    constructor() {
        this.quantity = 1;
        this.denominator = 1;
        this.package_type = "Small Parcel";
    }

    clone(): BookingCartItem {
        const clonedObject: any = new BookingCartItem();

        for(const attribute in this) {
            clonedObject[attribute] = this[attribute];
        }

        return clonedObject;
    }
}
