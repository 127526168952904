import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { OverlayManagerService } from '../overlay-manager.service';
import { SidebarService } from './sidebar.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  public readonly CLEAN_BOOKING = 0;

  constructor (private overlayManager: OverlayManagerService, private authSvc: AuthService, public sidebarService: SidebarService) { }

  ngOnInit(): void {
    this.sidebarService.checkAllowedFunctions();
  }

  openCleanBookingDialog() {
    this.overlayManager.showBookingPanel(this.CLEAN_BOOKING);
  }

  logout() {
    this.authSvc.logout();
  }

}
