import { Component, OnInit } from '@angular/core';
import { OverlayManagerService} from 'src/app/overlay-manager.service';
import { StaticDataService } from 'src/app/services/static-data.service';
import { CartDrawerManagerService } from '../cart-drawer/cart-drawer-manager.service';

@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.component.html',
  styleUrls: ['./workspace.component.scss']
})
export class WorkspaceComponent implements OnInit {  

  constructor( private cartManager: CartDrawerManagerService, private overlayManager: OverlayManagerService) {}

  ngOnInit() {

  }

  get overlayVisible() {
    return this.overlayManager.overlayVisible;
  }

  panelVisible(panel) {
    return this.overlayManager.panelVisible(panel);
  }

  get cartVisible() {
    return this.cartManager.cartVisible;
  }
}
