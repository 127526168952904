<div class='sidebar-width'>
  <div class="p-4">
    <div class="p-2">
      <img src="assets/TIC-Logo-For-Dark-BG.png" width="100%" />
    </div>
  </div>
  <!-- <div class="d-flex sidebar-btn align-content-center" (click)="openCleanBookingDialog()">
    <span class="flex-fill fas pl-2 fa-plus" style="width: 33%;"></span>
    <div class="flex-fill text-left" style="width: 66%;">
      New Booking
    </div>
  </div> -->
  <div *ngFor="let item of sidebarService.sidebarItems">
    <div class="d-flex sidebar-btn align-content-center" [routerLink]="[item.link]"
      routerLinkActive="sidebar-btn-active" *ngIf="item.allowed">
      <span class="flex-fill fas pl-2" [ngClass]="item.icon" style="width: 33%;"></span>
      <div class="flex-fill text-left" style="width: 66%;">
        {{item.name}}
      </div>
    </div>
  </div>
  <div class="d-flex sidebar-btn align-content-center" (click)="logout()">
    <span class="flex-fill fas pl-2 fa-sign-out-alt" style="width: 33%;"></span>
    <div class="flex-fill text-left" style="width: 66%;">
      Logout
    </div>
  </div>
</div>