import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'adminBookingFilter'
})
export class AdminBookingFilterPipe implements PipeTransform {

  transform(items: any[], filterTerm): unknown {

    if(!items || !filterTerm || filterTerm.length == 0) {
      return items;
    }

    return items.filter(function(item) {
      return item.Ref_No.replaceAll('-', '').toLowerCase().indexOf(filterTerm.toLowerCase()) !== -1;
    });
  }
}
