import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OverlayManagerService {

  PANEL_FILTER_DIALOG = 0;
  PANEL_BOOKING_DIALOG = 1;
  PANEL_IMAGE_GALLERY = 2;
  PANEL_ITEM_RECORD_PROFILE = 3;
  PANEL_GRN_ITEM_DIALOG = 4;

  currentPanel;

  overlayVisible = false;

  //For booking object references
  targetBookingItem;
  imageGallery;
  dataName;
  dataID;
  bookingItem;

  grnItemID;

  constructor () { }

  showFilterPanel(dataID, dataName) {
    this.dataID = dataID;
    this.dataName = dataName;
    this.currentPanel = this.PANEL_FILTER_DIALOG;
    this.overlayVisible = true;
  }

  showBookingPanel(items) {
    this.currentPanel = this.PANEL_BOOKING_DIALOG;
    this.overlayVisible = true;
  }

  showImageGallery(images) {
    this.imageGallery = images;
    this.currentPanel = this.PANEL_IMAGE_GALLERY;
    this.overlayVisible = true;
  }

  showBookingItemRecord(targetBookingItem) {
    this.bookingItem = targetBookingItem;
    this.overlayVisible = true;
    this.currentPanel = this.PANEL_ITEM_RECORD_PROFILE;
  }

  showGRNItemDialog(grnItemID) {
    this.grnItemID = grnItemID;
    this.overlayVisible = true;
    this.currentPanel = this.PANEL_GRN_ITEM_DIALOG;
  }

  panelVisible(panel) {
    return this.currentPanel == panel;
  }

  closePanel() {
    this.targetBookingItem = null;
    this.currentPanel = null;
    this.overlayVisible = false;
  }
}
