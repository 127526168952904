import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { StaticDataService } from './services/static-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  title = "tic-clarity-admin";
  authenticated = false;

  constructor(private authService: AuthService, private staticData: StaticDataService) {

    //deal with login
  }

  ngOnInit() {

    const tokens = (window.location.href).split("/");
    const promise = this.authService.login(tokens.pop());

    promise.then(response => {
      this.authenticated = true;
    }).catch(response => {
      window.location.href = "https://login.ticsin.com";      
    });

    this.staticData.init();
  }

  get loadingCompleted() {
    return this.staticData.loadingComplete;
  }

  get bigScreen() {    
    return window.innerWidth ? window.innerWidth > 414 : true;
  }
}
