<div class="d-flex flex-fill" *ngIf="!authenticated || !loadingCompleted">
    <div class="progress">
        <div class="indeterminate"></div>
    </div>    
</div>
<div class="d-flex flex-fill" *ngIf="authenticated && loadingCompleted">
    <div class="d-flex flex-fill" *ngIf="bigScreen">
        <app-workspace></app-workspace>
    </div>
    <div class="d-flex flex-fill" *ngIf="!bigScreen">
        <app-mobile-workspace></app-mobile-workspace>
    </div>
</div>